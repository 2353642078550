import { default as index72V5OWziAGMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as indexHNehh6cqSXMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue?macro=true";
import { default as indexaH6rIM9BuwMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/change/email/index.vue?macro=true";
import { default as indexNzAIM24JRXMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/change/password/index.vue?macro=true";
import { default as crezco_45loadingXJcCWPA42XMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/crezco-loading.vue?macro=true";
import { default as index0B75uOQ0XBMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/index.vue?macro=true";
import { default as InvoicesldBV26d3MvMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/Invoices.vue?macro=true";
import { default as indexM8ApSWrLoAMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/login/index.vue?macro=true";
import { default as indexa407V8nLS5Meta } from "/usr/src/customer-portal-nuxt3.develop/pages/validate/email/index.vue?macro=true";
import { default as indexTY0li5hFNyMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/validate/password/index.vue?macro=true";
import { default as indexxjHue790RnMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/validate/phone/index.vue?macro=true";
import { default as index5BcjLT0hN3Meta } from "/usr/src/customer-portal-nuxt3.develop/pages/widget/[businessCode]/[group]/index.vue?macro=true";
import { default as indexEvI0SEhD2TMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/widget/chat/[businessCode]/[group]/index.vue?macro=true";
import { default as index6RCTvll9rGMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/widget/conversations/[businessCode]/[group]/index.vue?macro=true";
import { default as indexQJv1aG8ojqMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/widget/documents/[businessCode]/[group]/index.vue?macro=true";
import { default as index3AgNQGlGBuMeta } from "/usr/src/customer-portal-nuxt3.develop/pages/widget/payments/[businessCode]/[group]/index.vue?macro=true";
export default [
  {
    name: "businessCode-group-invoiceCode",
    path: "/:businessCode()/:group()/:invoiceCode()",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/[businessCode]/[group]/[invoiceCode]/index.vue").then(m => m.default || m)
  },
  {
    name: "invoice-page",
    path: "/:type()/:businessCode()/:group()/:invoiceCode()",
    meta: indexHNehh6cqSXMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/[type]/[businessCode]/[group]/[invoiceCode]/index.vue").then(m => m.default || m)
  },
  {
    name: "change-email",
    path: "/change/email",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/change/email/index.vue").then(m => m.default || m)
  },
  {
    name: "change-password",
    path: "/change/password",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/change/password/index.vue").then(m => m.default || m)
  },
  {
    name: "crezco-loading",
    path: "/crezco-loading",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/crezco-loading.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Invoices",
    path: "/Invoices",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/Invoices.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-email",
    path: "/validate/email",
    meta: indexa407V8nLS5Meta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/validate/email/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-password",
    path: "/validate/password",
    meta: indexTY0li5hFNyMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/validate/password/index.vue").then(m => m.default || m)
  },
  {
    name: "validate-phone",
    path: "/validate/phone",
    meta: indexxjHue790RnMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/validate/phone/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-businessCode-group",
    path: "/widget/:businessCode()/:group()",
    meta: index5BcjLT0hN3Meta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/widget/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-chat-businessCode-group",
    path: "/widget/chat/:businessCode()/:group()",
    meta: indexEvI0SEhD2TMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/widget/chat/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-conversations-businessCode-group",
    path: "/widget/conversations/:businessCode()/:group()",
    meta: index6RCTvll9rGMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/widget/conversations/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-documents-businessCode-group",
    path: "/widget/documents/:businessCode()/:group()",
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/widget/documents/[businessCode]/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: "widget-payments-businessCode-group",
    path: "/widget/payments/:businessCode()/:group()",
    meta: index3AgNQGlGBuMeta || {},
    component: () => import("/usr/src/customer-portal-nuxt3.develop/pages/widget/payments/[businessCode]/[group]/index.vue").then(m => m.default || m)
  }
]